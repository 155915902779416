import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/layout/Layout";
import EditTherapy from "./pages/therapy/edit-therapy/EditTherapy";
import ManageTherapies from "./pages/therapy/manage-therapies/ManageTherapies";
import CreateTherapists from "./pages/therapists/create-therapists/CreateTherapists";
import EditTherapists from "./pages/therapists/edit-therapists/EditTherapists";
import ManageTherapists from "./pages/therapists/manage-therapists/ManageTherapists";
import CreateStudent from "./pages/student/create-student/CreateStudent";
import ManageStudents from "./pages/student/manage-students/ManageStudents";
import EditStudent from "./pages/student/edit-student/EditStudent";
import CreateGoalsActivities from "./pages/goals-activities/create-goals-activities/CreateGoalsActivities";
import EditGoalsActivities from "./pages/goals-activities/edit-goals-activities/EditGoalsActivities";
import ManageGoalsActivities from "./pages/goals-activities/manage-goals-activities/ManageGoalsActivities";
import CreateSessionProgressUpdate from "./pages/therapy-logs/create-session-feedback/CreateSessionFeedback";
import ViewPayments from "./pages/payment-dues/view-payment-dues/ViewPayments";
import ViewRecordFeePayment from "./pages/record-fee-payment/view-record-fee-payment/ViewRecordFeePayment";
import CreateAnnouncements from "./pages/announcements/create-announcements/CreateAnnouncements";
import EditAnnouncements from "./pages/announcements/edit-announcements/EditAnnouncements";
import ManageAnnouncements from "./pages/announcements/manage-announcements/ManageAnnouncements";
import ManagePayments from "./pages/record-fee-payment/manage-payments/ManagePayments";
import Login from "./pages/login/Login";
import ForgotPassword from "./pages/forgotpassword/ForgotPassword";
import ChangePassword from "./pages/changepassword/ChangePassword";
import CreateAccount from "./pages/admin/accounts/CreateAccount/CreateAccount";
import ManageAccounts from "./pages/admin/accounts/ManageAccounts/ManageAccounts";
import EditAccount from "./pages/admin/accounts/EditAccount/EditAccount";
import EditRecordFeePayment from "./pages/record-fee-payment/edit-payments/EditPayments";
import Website from "./pages/website/Website";
import PrivacyPolicy from "./pages/website/PrivacyPolicy";
import TermsandConditions from "./pages/website/Terms-and-Conditions";
import Dashboard from "./pages/dashboard/Dashboard";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import Profile from "./pages/profile/Profile";
import SessionFeedback from "./pages/therapy-logs/view-session-feedback/SessionFeedback";
import SessionsFeedback from "./pages/therapy-logs/sessions-feedback/SessionsFeedback";
import CreateSessionFeedback from "./pages/therapy-logs/create-session-feedback/CreateSessionFeedback";
import EditSessionFeedback from "./pages/therapy-logs/edit-session-feedback/EditSessionFeedback";
import MorePage from "./pages/morepage/MorePage";
import MobileLayout from "./pages/layout/MobileLayout";
import MobileDashboard from "./pages/dashboard/MobileDashboard";
import MobileStudents from "./pages/student/manage-students/MobileStudents";
import MobileCreateStudent from "./pages/student/create-student/MobileCreateStudent";
import MobileEditStudent from "./pages/student/edit-student/MobileEditStudent";
import MobileCreateTherapist from "./pages/therapists/create-therapists/MobileCreateTherapist";
import MobileTherapists from "./pages/therapists/manage-therapists/MobileTherapists";
import MobileEditTherapist from "./pages/therapists/edit-therapists/MobileEditTherapist";
import MobileTherapies from "./pages/therapy/manage-therapies/MobileTherapies";
import MobileEditTherapy from "./pages/therapy/edit-therapy/MobileEditTherapy";
import CreateTherapy from "./pages/therapy/create-therapy/CreateTherapy";
import MobileCreateTherapy from "./pages/therapy/create-therapy/MobileCreateTherapy";
import MobileSessionsFeedback from "./pages/therapy-logs/sessions-feedback/MobileSessionsFeedback";
import MobileCreateSessions from "./pages/therapy-logs/create-session-feedback/MobileCreateSessions";
import MobileEditSessions from "./pages/therapy-logs/edit-session-feedback/MobileEditSessions";
import MobileGoals from "./pages/goals-activities/manage-goals-activities/MobileGoals";
import MobileCreateGoals from "./pages/goals-activities/create-goals-activities/MobileCreateGoals";
import MobileEditGoals from "./pages/goals-activities/edit-goals-activities/MobileEditGoals";
import MobileCreateAnnouncements from "./pages/announcements/create-announcements/MobileCreateAnnouncements";
import MobileEditAnnouncements from "./pages/announcements/edit-announcements/MobileEditAnnouncements";
import MobileAnnouncements from "./pages/announcements/manage-announcements/MobileAnnouncements";
import MobilePayments from "./pages/record-fee-payment/manage-payments/MobilePayments";
import MobileViewPayments from "./pages/record-fee-payment/view-record-fee-payment/MobileViewPayments";
import MobileEditPayments from "./pages/record-fee-payment/edit-payments/MobileEditPayments";
import MobileResetPassword from "./pages/resetpassword/MobileResetPassword";

function App() {
  
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Website />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-conditions" element={<TermsandConditions />} />
                <Route path="/app/login" element={<Login />} />
                <Route path="/app/forgot-password" element={<ForgotPassword />} />
                <Route path="/app/change-password" element={<ChangePassword />} />
                {window.innerWidth >= 992 && <>
                <Route path="/app" element={<Layout />}>
                <Route path="/app/reset-password" element={<ResetPassword />} />
                <Route path="/app/dashboard" element={<Dashboard />} />
                <Route path="/app/sessions-feedback" element={<SessionsFeedback />} />
                <Route path="/app/profile" element={<Profile />} />
                <Route path="/app/create-therapy" element={<CreateTherapy />} />
                <Route path="/app/edit-therapy/:therapy_id" element={<EditTherapy />} />
                <Route path="/app/manage-therapies" element={<ManageTherapies />} />
                <Route path="/app/create-therapists" element={<CreateTherapists />} />
                <Route path="/app/edit-therapists/:therapist_correl_id" element={<EditTherapists />} />
                <Route path="/app/manage-therapists" element={<ManageTherapists />} />
                <Route path="/app/create-student" element={<CreateStudent />} />
                <Route path="/app/edit-student/:student_correl_id" element={<EditStudent />} />
                <Route path="/app/manage-students" element={<ManageStudents />} />
                <Route path="/app/create-goals-activities" element={<CreateGoalsActivities />} />
                <Route path="/app/edit-goals-activities/:goal_correl_id" element={<EditGoalsActivities />} />
                <Route path="/app/manage-goals-activities" element={<ManageGoalsActivities />} />
                <Route path="/app/create-session-feedback" element={<CreateSessionFeedback />} />
                <Route path="/app/edit-session-feedback/:therapy_log_id" element={<EditSessionFeedback />} />
                <Route path="/app/view-session-feedback/:therapy_log_id" element={<SessionFeedback />} />
                <Route path="/app/payment-dues" element={<ViewPayments />} />
                <Route path="/app/view-record-fee-payment" element={<ViewRecordFeePayment />} />
                <Route path="/app/edit-payments/:payment_id" element={<EditRecordFeePayment />} />
                <Route path="/app/manage-payments" element={<ManagePayments />} />
                <Route path="/app/create-announcements" element={<CreateAnnouncements />} />
                <Route path="/app/edit-announcements/:announcement_id" element={<EditAnnouncements />} />
                <Route path="/app/manage-announcements" element={<ManageAnnouncements />} />

                </Route>

                <Route path="/admin" element={<Layout />}>
                <Route index path="/admin/create-account" element={<CreateAccount />} />
                <Route index path="/admin/edit-account/:account_code" element={<EditAccount />} />
                <Route index path="/admin/manage-accounts" element={<ManageAccounts />} />
                {/* <Route index path="/admin/manage-therapists" element={<ManageAdminTherapists />} />
                <Route index path="/admin/manage-students" element={<ManageAdminStudents />} />
                <Route index path="/admin/view-students/:student_correl_id" element={<ViewAdminStudents />} />
                <Route index path="/admin/view-therapists/:therapist_correl_id" element={<ViewAdminTherapists />} /> */}
                </Route>
                </>}
                {window.innerWidth < 992 && <>
                  <Route path="/app" element={<MobileLayout />} >
                  <Route path="/app/reset-password" element={<MobileResetPassword />} />
                  <Route path="/app/dashboard" element={<MobileDashboard />} />
                  <Route path="/app/manage-students" element={<MobileStudents />} />
                  <Route path="/app/create-student" element={<MobileCreateStudent />} />
                  <Route path="/app/edit-student/:student_correl_id" element={<MobileEditStudent />} />
                  <Route path="/app/manage-therapists" element={<MobileTherapists />} />
                  <Route path="/app/create-therapists" element={<MobileCreateTherapist />} />
                  <Route path="/app/edit-therapists/:therapist_correl_id" element={<MobileEditTherapist />} />
                  <Route path="/app/create-therapy" element={<MobileCreateTherapy />} />
                  <Route path="/app/edit-therapy/:therapy_id" element={<MobileEditTherapy />} />
                  <Route path="/app/manage-therapies" element={<MobileTherapies />} />
                  <Route path="/app/sessions-feedback" element={<MobileSessionsFeedback />} />
                  <Route path="/app/create-session-feedback" element={<MobileCreateSessions />} />
                  <Route path="/app/edit-session-feedback/:therapy_log_id" element={<MobileEditSessions />} />
                  <Route path="/app/profile" element={<Profile />} />
                  <Route path="/app/manage-goals-activities" element={<MobileGoals/>} />
                  <Route path="/app/create-goals-activities" element={<MobileCreateGoals />} />
                  <Route path="/app/edit-goals-activities/:goal_correl_id" element={<MobileEditGoals />} />
                  <Route path="/app/create-announcements" element={<MobileCreateAnnouncements />} />
                  <Route path="/app/edit-announcements/:announcement_id" element={<MobileEditAnnouncements />} />
                  <Route path="/app/manage-announcements" element={<MobileAnnouncements />} />
                  <Route path="/app/manage-payments" element={<MobilePayments />} />
                  <Route path="/app/view-record-fee-payment" element={<MobileViewPayments />} />
                  <Route path="/app/edit-payments/:payment_id" element={<MobileEditPayments />} />

                  <Route path="/app/morepage" element={<MorePage />} />

                  </Route>
                </>}
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
