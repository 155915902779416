import React, { useEffect, useState } from 'react'
import "./managestudents.scss"
import MobileFooter from '../../../components/mobile-footer/MobileFooter'
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronRight, ControlPoint, Search } from '@mui/icons-material';
import { deleteStudent, getStudents } from '../../../Api';

function MobileStudents() {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [totalPages, setTotalPages] = useState(null);
    const [content, setContent] = React.useState([]);

    const filteredData = [...content].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const edit = (student_correl_id) => {
        navigate('/app/edit-student/' + student_correl_id);
    }
    const delStudent = (student_id) => {
        Swal.fire({
            text: `Are you sure you want to delete the student?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let apiRes = await deleteStudent(student_id);
                console.log(apiRes);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: `Student Deleted`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        fetchData();
                    });
                }
            }
        })
    }

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let acc_code = userData.account_code;
        let apiData = await getStudents(acc_code);
        apiData.status === "S" ? setContent(apiData.result_info) : setContent([]);
        apiData.status === "S" ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)) : setTotalPages(0);
        if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
        if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
    }

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let acc_code = userData.account_code;
            let apiData = await getStudents(acc_code);
            apiData.status === "S" ? setContent(apiData.result_info) : setContent([]);
            apiData.status === "S" ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)) : setTotalPages(0);
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        })();
    }, [navigate])
    return (
        <div className='mobile-managestudents'>
            <div className='header-container'>
                <div className='create-icon'>
                    <Link to="/app/create-student" className='text-decoration-none'>
                        <ControlPoint className='create' />
                    </Link>
                </div>
                <div className='heading-div'>
                    <h5 className='page-heading mb-0 text-white'>Students</h5>
                </div>
            </div>
            <div className='mobile-screen'>
                <div className="search-container">
                    <input
                        type="search"
                        className="search-input"
                        placeholder="Search"
                        aria-label="Search"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <Search className="search-icon" />
                </div>

                {paginatedData && paginatedData.length > 0 && paginatedData.map((row, index) => (

                    <div className="card mobile-card" onClick={() => edit(row.student_correl_id)}>
                        <div key={index} className='d-flex justify-content-between align-items-center'>
                            <h6 className='mb-0 card-content'>{row.student_name}</h6>
                            <ChevronRight className='iconColor' />
                        </div>
                    </div>
                ))}
            </div>
            <div className='footer-pad'>
                <MobileFooter />
            </div>
        </div>
    )
}

export default MobileStudents
