import React from 'react'
import "./sidebar.scss"
import { Link, useLocation } from 'react-router-dom';
import { MdLocalActivity, MdLockReset, MdManageAccounts, MdOutlinePowerSettingsNew } from 'react-icons/md';
import { FaClinicMedical, FaMoneyCheck, FaUserNurse } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { FormatListBulleted, Home, People, Person } from '@mui/icons-material';

function Sidebar({ stateVal, trigger, role }) {

    const location = useLocation();
    const { pathname } = location;

    const closeSideMenu = () => {
        if (window.innerWidth < 992) {
            trigger()
        }
    }
    return (
        <div className={`sidebar sidebarContainer ${stateVal ? '' : 'd-none'}`}>
            {+role === 2 && <div className="sidebarContent">

                <Link to='/app/dashboard' onClick={() => { closeSideMenu() }} className="sidebar-link align-items-center">
                    <div className={pathname === '/app/dashboard' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <Home className='icon' />
                        {/* <IonIcon name="home-outline" className='icon' /> */}
                        <p className="actualText">Dashboard</p>
                    </div>
                </Link>
                <Link to='/app/manage-students' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/manage-students' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <People className='icon' />
                        {/* <IonIcon name="people" className='icon' /> */}
                        <p className="actualText">Students</p>
                    </div>
                </Link>
                <Link to='/app/sessions-feedback' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/sessions-feedback' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <FormatListBulleted className='icon' />
                        {/* <IonIcon name="list" className='icon' /> */}
                        <p className="actualText">Sessions</p>
                    </div>
                </Link>
                <Link to='/app/manage-therapies' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/manage-therapies' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <FaClinicMedical className='icon' />
                        <p className="actualText">Therapies</p>
                    </div>
                </Link>
                <Link to='/app/manage-therapists' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/manage-therapists' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <FaUserNurse className='icon' />
                        {/* <FontAwesomeIcon icon={faDoctor}  /> */}
                        <p className="actualText">Therapists</p>
                    </div>
                </Link>

                <Link to='/app/manage-goals-activities' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/manage-goals-activities' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <MdLocalActivity className='icon' />
                        <p className="actualText">Goals</p>
                    </div>
                </Link>
                <Link to="/app/manage-payments" onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/manage-payments' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <FaMoneyCheck className='icon' />
                        <p className="actualText">Payments</p>
                    </div>
                </Link>
                <Link to="/app/profile" onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/profile' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <IoPerson className='icon' />
                        <p className="actualText">Profile</p>
                    </div>
                </Link>
                <Link to="/app/reset-password" onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/reset-password' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <MdLockReset className='icon' />
                        <p className="actualText">Reset Password</p>
                    </div>
                </Link>
                <Link to="/app/login" onClick={() => {
                    closeSideMenu();
                    localStorage.clear()
                }} className="sidebar-link">
                    <div className="subContent mb-2">
                        <MdOutlinePowerSettingsNew className='icon' />
                        <p className='actualText'>Log Out</p>
                    </div>
                </Link>
            </div>}
            {+role === 3 && <div className="sidebarContent">
                <Link to='/app/dashboard' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/dashboard' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <Home className='icon' />
                        <p className="actualText">Dashboard</p>
                    </div>
                </Link>
                <Link to='/app/sessions-feedback' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/sessions-feedback' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <FormatListBulleted className='icon' />
                        <p className="actualText">Sessions</p>
                    </div>
                </Link>
                <Link to="/app/profile" onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/profile' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <IoPerson className='icon' />
                        <p className="actualText">Profile</p>
                    </div>
                </Link>
                <Link to="/app/reset-password" onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/therapist/therapist-resetpassword' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <MdLockReset className='icon' />
                        <p className="actualText">Reset Password</p>
                    </div>
                </Link>
                <Link to="/app/login" onClick={() => {
                    closeSideMenu();
                    localStorage.clear()
                }} className="sidebar-link">
                    <div className="subContent mb-2">
                        <MdOutlinePowerSettingsNew className='icon' />
                        <p className='actualText'>Log Out</p>
                    </div>
                </Link>
            </div>}
            {+role === 4 && <div className="sidebarContent">
                <Link to='/app/dashboard' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/dashboard' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <Home className='icon' />
                        <p className="actualText">Dashboard</p>
                    </div>
                </Link>
                <Link to='/app/sessions-feedback' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/sessions-feedback' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <FormatListBulleted className='icon' />
                        <p className="actualText">Sessions</p>
                    </div>
                </Link>
                <Link to="/app/profile" onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/profile' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <IoPerson className='icon' />
                        <p className="actualText">Profile</p>
                    </div>
                </Link>
                <Link to="/app/reset-password" onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/reset-password' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <MdLockReset className='icon' />
                        <p className="actualText">Reset Password</p>
                    </div>
                </Link>
                <Link to="/app/login" onClick={() => {
                    closeSideMenu();
                    localStorage.clear()
                }} className="sidebar-link">
                    <div className="subContent mb-2">
                        <MdOutlinePowerSettingsNew className='icon' />
                        <p className='actualText'>Log Out</p>
                    </div>
                </Link>
            </div>}

            {+role === 1 && <div className="sidebarContent">
                <Link to='/app/dashboard' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/dashboard' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <Home className='icon' />
                        <p className="actualText">Dashboard</p>
                    </div>
                </Link>
                <Link to='/admin/manage-accounts' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/admin/manage-accounts' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <MdManageAccounts className='icon' />
                        <p className="actualText">Accounts</p>
                    </div>
                </Link>
                <Link to='/app/sessions-feedback' onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/sessions-feedback' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <FormatListBulleted className='icon' />
                        <p className="actualText">Sessions</p>
                    </div>
                </Link>
                <Link to="/app/reset-password" onClick={() => { closeSideMenu() }} className="sidebar-link">
                    <div className={pathname === '/app/reset-password' ? 'subContent mb-2 active' : 'subContent mb-2'}>
                        <MdLockReset className='icon' />
                        <p className="actualText">Reset Password</p>
                    </div>
                </Link>
                <Link to="/app/login" onClick={() => {
                    closeSideMenu();
                    localStorage.clear()
                }} className="sidebar-link">
                    <div className="subContent mb-2">
                        <MdOutlinePowerSettingsNew className='icon' />
                        <p className='actualText'>Log Out</p>
                    </div>
                </Link>
            </div>}

        </div>
    )
}

export default Sidebar
