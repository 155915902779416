import React, { useEffect, useState } from 'react'
import "./more.scss"
import { ChevronRight } from '@mui/icons-material'
import MobileFooter from '../../components/mobile-footer/MobileFooter';
import { Link } from 'react-router-dom';

function MorePage() {
    const [isRole, setIsRole] = useState(null);
    useEffect(() => {
        let userRole = JSON.parse(localStorage.getItem('role'));
        setIsRole(userRole);
        console.log(userRole);
    }, []);
    return (
        <div className='morepage'>
            <div className='bg-color'>
                <h5 className='page-heading'>More</h5>
            </div>
            <div className='contentContainer mt-3'>
                {+isRole === 2 && (<>
                    <Link to='/app/manage-therapies' className='text-decoration-none text-black'>
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Therapies</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                    <Link to='/app/manage-therapists' className='text-decoration-none text-black'>
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Therapists</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                    <Link to='/app/manage-goals-activities' className='text-decoration-none text-black'>
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Goals</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                    <Link to='/app/manage-payments' className='text-decoration-none text-black'>
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Payments</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                    {/* <Link to='/app/manage-announcements' className='text-decoration-none text-black'>
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Announcements</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link> */}
                    <Link to='/app/profile' className='text-decoration-none text-black'>
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Profile</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                    <Link to='/app/reset-password' className='text-decoration-none text-black'>
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Reset Password</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                    <Link to="/app/login" onClick={() => {
                        localStorage.clear()
                    }} className="sidebar-link">
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Logout</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                </>
                )}
                {+isRole === 3 && (<>
                    <Link to='/app/reset-password' className='text-decoration-none text-black'>
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Reset Password</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                    <Link to="/app/login" onClick={() => {
                        localStorage.clear()
                    }} className="sidebar-link">
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Logout</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                </>
                )}
                {+isRole === 4 && (<>
                    <Link to='/app/reset-password' className='text-decoration-none text-black'>
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Reset Password</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                    <Link to="/app/login" onClick={() => {
                        localStorage.clear()
                    }} className="sidebar-link">
                        <div className='mobile-card'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>Logout</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                        </div>
                    </Link>
                </>
                )}
            </div>
            <div className='footer-pad'>
                <MobileFooter />
            </div>

        </div>
    )
}

export default MorePage
