import React, { useCallback, useEffect, useState } from 'react'
import "./dashboard.scss"
import { Link, useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import { getAdminDashboard, getStudentDashboard, getTherapistDashboard } from '../../Api';
import Swal from 'sweetalert2';
import { FormatListBulleted } from '@mui/icons-material';
import { MdLocalActivity } from 'react-icons/md';
import { TfiTarget } from 'react-icons/tfi';
import { FaClinicMedical, FaMoneyCheck, FaUserNurse } from 'react-icons/fa';
import MobileFooter from '../../components/mobile-footer/MobileFooter';

function MobileDashboard() {
    const { role } = useOutletContext();
    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const [isRole, setIsRole] = useState(null);
    const [content, setContent] = useState({
        total_students: "",
        total_therapists: "",
        today_summary: {},
        monthly_summary: {}

    });

    const [userData, setUserData] = useState({
        businessName: '',
        therapistName: '',
        parentName: ''
    });

    useEffect(() => {
        const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserData({
            businessName: storedUserData.business_name || '',
            therapistName: storedUserData.therapist_name || '',
            parentName: storedUserData.parent_name || ''
        });
    }, []);

    const fetchRecords = useCallback(async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let userRole = JSON.parse(localStorage.getItem('role'));
        setIsRole(userRole);
        console.log(userData.account_code);
        let apiRes;

        let currentDate = new Date();
        const month = selectedMonth.month();
        let formattedDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();

        if (+userRole === 2) {
            apiRes = await getAdminDashboard(userData.account_code, formattedDate, month);
        }
        if (+userRole === 3) {
            apiRes = await getTherapistDashboard(userData.account_code, userData.therapist_id, formattedDate, month);
        }
        if (+userRole === 4) {
            apiRes = await getStudentDashboard(userData.account_code, userData.student_id, formattedDate, month);
        }
        let data = await apiRes;
        console.log(data, "response came here");

        if (data.status === 'S') {
            data.today_summary.total_activities = data.today_summary.total_activities === null ? 0 : data.today_summary.total_activities;
            setContent(data);
        } else if (data.result_code === 404) {
            setContent({});
        } else {
            setContent({});
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong!',
            });
        }

    }, []);

    useEffect(() => {
        fetchRecords()
    }, [fetchRecords])

    const RenderAdminSummary = () => {
        return (
            <div className='text-center'>
                <h5 className='section-heading mb-2 text-start mb-2'>Today's Summary</h5>
                {content &&
                    <div className="row">
                        <div className="col-6 col-lg-6">
                            <Link to='/app/sessions-feedback' className='textDecoration'>
                                <div className="card color-card1 text-start">
                                    <div className='mb-2'>
                                        <FormatListBulleted className='text-center' />
                                    </div>
                                    <p className="summary-names mb-2">Sessions</p>
                                    <h5 className='mb-0'>{content.today_summary.total_sessions}</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-6 col-lg-6">
                            <Link to='/app/sessions-feedback' className='textDecoration'>
                                <div className="card color-card2 text-start">
                                    <div className='mb-2'>
                                        <MdLocalActivity className='text-center' />
                                    </div>
                                    <p className="summary-names mb-2">Activities</p>
                                    <h5 className='mb-0'>{content.today_summary.total_activities}</h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                }
            </div>
        )
    };
    const RenderMonthlySummary = () => {
        return (
            <div className='text-center mt-4'>
                <h5 className='section-heading mb-2 text-start mt-2 mb-2'>Monthly Summary</h5>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card common-card text-start">
                                <div className='justify-content-space-between'>
                                    <div className='d-flex align-items-center'>
                                        <FormatListBulleted className='text-center me-2' />
                                        <p className="summary-names mb-0">Sessions</p>
                                    </div>
                                    <h5 className='mb-0'>{content.monthly_summary.total_sessions}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-lg-4">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card common-card text-start">
                                <div className='justify-content-space-between'>
                                    <div className='d-flex align-items-center'>
                                        <MdLocalActivity className='text-center me-2' />
                                        <p className="summary-names mb-0">Activities</p>
                                    </div>
                                    <h5 className='mb-0'>{content.monthly_summary.total_activities}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-lg-4">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card common-card text-start">
                                <div className='justify-content-space-between'>
                                    <div className='d-flex align-items-center'>
                                        <TfiTarget className='text-center me-2' />
                                        <p className="summary-names mb-0">Goals</p>
                                    </div>
                                    <h5 className='mb-0'>{content.monthly_summary.total_goals}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
    const RenderQuickLinks = () => {
        return (
            <div className='text-center mt-4'>
                <h5 className='section-heading mb-2 text-start mt-2 mb-3'>Quick Links</h5>
                <div className="row g-3">
                    <div className="col-6 col-lg-6">
                        <Link to='/app/manage-payments' className='text-decoration-none'>
                            <div className="card color-card1 text-start mt-0">
                                <FaMoneyCheck className='text-center mb-2' />
                                <p className="summary-names mb-0">Payments</p>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-6">
                        <Link to='/app/manage-therapists' className='text-decoration-none'>
                            <div className="card color-card2 text-start mt-0">
                                <FaUserNurse className='text-center mb-2' />
                                <p className="summary-names mb-0">Therapists</p>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-6">
                        <Link to='/app/manage-goals-activities' className='text-decoration-none'>
                            <div className="card color-card2 text-start mt-0">
                                <MdLocalActivity className='text-center mb-2' />
                                <p className="summary-names mb-0">Goals</p>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-6">
                        <Link to='/app/manage-therapies' className='text-decoration-none'>
                            <div className="card color-card1 text-start mt-0">
                                <FaClinicMedical className='text-center mb-2' />
                                <p className="summary-names mb-0">Therapies</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
    const RenderTherapistSummary = () => {
        return (
            <div className='text-center'>
                <h5 className='section-heading mb-2 text-start'>Today’s Summary</h5>
                <div className="row">
                    <div className="col-6 col-lg-6">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card color-card1 text-start" style={{ backgroundColor: '#ececec' }}>
                                <div className='mb-2'>
                                    <FormatListBulleted className='text-center' />
                                </div>
                                <p className="summary-names mb-2">Sessions</p>
                                <h5 className='mb-0'>{content.today_summary.total_sessions}</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-6">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card color-card2 text-start">
                                <div className='mb-2'>
                                    <MdLocalActivity className='text-center' />
                                </div>
                                <p className="summary-names mb-2">Activities</p>
                                <h5 className='mb-0'>{content.today_summary.total_activities}</h5>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
    const RenderTherapistMonthlySummary = () => {
        return (
            <div className='text-center mt-4 '>
                <h5 className='section-heading mb-2 text-start mt-2'>Monthly Summary</h5>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card common-card text-start">
                                <div className='justify-content-space-between'>
                                    <div className='d-flex align-items-center'>
                                        <FormatListBulleted className='text-center me-2' />
                                        <p className="summary-names mb-0">Sessions</p>
                                    </div>
                                    <h5 className='mb-0'>{content.monthly_summary.total_sessions}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-lg-4">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card common-card text-start">
                                <div className='justify-content-space-between'>
                                    <div className='d-flex align-items-center'>
                                        <MdLocalActivity className='text-center me-2' />
                                        <p className="summary-names mb-0">Activities</p>
                                    </div>
                                    <h5 className='mb-0'>{content.monthly_summary.total_activities}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-lg-4">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card common-card text-start">
                                <div className='justify-content-space-between'>
                                    <div className='d-flex align-items-center'>
                                        <TfiTarget className='text-center me-2' />
                                        <p className="summary-names mb-0">Goals</p>
                                    </div>
                                    <h5 className='mb-0'>{content.monthly_summary.total_goals}</h5>
                                </div>
                            </div>
                        </Link>

                    </div>
                </div>

            </div>
        )
    }
    const RenderParentSummary = () => {
        return (
            <div className='text-center'>
                <h5 className='section-heading mb-2 text-start'>Today’s Summary</h5>
                <div className="row">
                    <div className="col-6 col-lg-6">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card color-card1 text-start">
                                <div className='mb-2'>
                                    <FormatListBulleted className='text-center' />
                                </div>
                                <p className="summary-names mb-2">Sessions</p>
                                <h5>{content.today_summary.total_sessions}</h5>
                            </div>
                        </Link>
                    </div >
                    <div className="col-6 col-lg-6">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card color-card2 text-start">
                                <div className='mb-2'>
                                    <MdLocalActivity className='text-center' />
                                </div>
                                <p className="summary-names mb-2">Activities</p>
                                <h5>{content.today_summary.total_activities}</h5>
                            </div>
                        </Link>
                    </div>
                </div >
            </div >
        )
    }
    const RenderParentMonthlySummary = () => {
        return (
            <div className='text-center'>
                <h5 className='section-heading mb-2 text-start mt-4'>Monthly Summary</h5>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card common-card text-start">
                                <div className='justify-content-space-between'>
                                    <div className='d-flex align-items-center'>
                                        <FormatListBulleted className='text-center me-2' />
                                        <p className="summary-names mb-0">Sessions</p>
                                    </div>
                                    <h5 className='mb-0'>{content.monthly_summary.total_sessions}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-lg-4">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card common-card text-start">
                                <div className='justify-content-space-between'>
                                    <div className='d-flex align-items-center'>
                                        <MdLocalActivity className='text-center me-2' />
                                        <p className="summary-names mb-0">Activities</p>
                                    </div>
                                    <h5 className='mb-0'>{content.monthly_summary.total_activities}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    {/* <div className="col-12 col-lg-4">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card common-card text-start">
                                <div className='justify-content-space-between'>
                                    <div className='d-flex align-items-center'>
                                        <MdLocalActivity className='text-center me-2' />
                                        <p className="summary-names mb-0">Goals</p>
                                    </div>
                                    <h5 className='mb-0'>{content.monthly_summary.total_goals}</h5>
                                </div>
                            </div>
                        </Link>

                    </div> */}

                </div>
            </div>
        )
    }
    return (
        <div className='mobile-dashboard'>
            {+role === 2 && (
                <>
                    <div className='bg-color admin-dashboard flex-column'>
                        <h5 className='page-heading px-4 mt-0'>Hello {userData.businessName}</h5>
                        <div className='curvy-div'>
                            <div className='innercurvy-div'>
                            </div>
                        </div>
                    </div>

                    <div className='contentContainer admin-dashboard'>
                        <RenderAdminSummary />
                        <RenderMonthlySummary />
                        <RenderQuickLinks />
                    </div>

                </>
            )}
            {+role === 3 && (
                <div className='therapistInfo'>
                    <div className='bg-color flex-column'>
                        <h5 className='therapist-name page-heading px-4 mt-0 '>Hello {userData.therapistName}</h5>
                        <div className='curvy-div'>
                            <div className='innercurvy-div'>
                            </div>
                        </div>
                    </div>
                    <div className='contentContainer'>
                        <RenderTherapistSummary />
                        <RenderTherapistMonthlySummary />
                    </div>

                </div>
            )}
            {+role === 4 && (
                <>
                    <div className='bg-color flex-column'>
                        <h5 className='student-name page-heading px-4 mt-0'>Hello {userData.parentName}</h5>
                        <div className='curvy-div'>
                            <div className='innercurvy-div'>
                            </div>
                        </div>
                    </div>
                    <div className='contentContainer'>
                        <RenderParentSummary />
                        <RenderParentMonthlySummary />
                    </div>
                </>
            )}
            <div className='footer-pad'>
                <MobileFooter />
            </div>

        </div>
    )
}

export default MobileDashboard
