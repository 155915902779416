import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';

function MobileLayout() {
    const navigate = useNavigate();
    const [role, setRole] = useState(null);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let userRole = JSON.parse(localStorage.getItem('role'));
        console.log(userData);
        if (!userRole) {
            navigate('/app/login')
        } else {
            setRole(userRole);
        };
    }, [])

    return (
        <>
            <Outlet context={{ role }} />
        </>
    )
}

export default MobileLayout
