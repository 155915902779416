import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./login.css";
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2';
import { loginApi } from "../../Api";

function Login() {
    const navigate = useNavigate()
    const [userObj, setUserObj] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        // Clear localStorage when the component mounts
        localStorage.clear();
    }, []);
    const submit = async () => {
        let formData = { ...userObj };
        formData.email = formData.email.toLowerCase();
        formData.password = formData.password.toLowerCase();
        console.log(formData);
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        let pwd = new RegExp('^[a-zA-Z0-9.@]+$');
        if (!formData.email) {
            Swal.fire({
                text: 'Email required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!emalval.test(formData.email)) {
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!formData.password) {
            Swal.fire({
                text: 'Please provide password!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!pwd.test(formData.password)) {
            Swal.fire({
                text: 'Enter valid Password',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let loginData = await loginApi(formData);
            if (loginData.status === 'S') {
                localStorage.setItem('userData', JSON.stringify(loginData.result_info));
                localStorage.setItem('token', JSON.stringify(loginData.token));
                localStorage.setItem('role', JSON.stringify(loginData.role));
                localStorage.setItem('correl_id', loginData.correl_id);

                if (loginData.result_info.therapist_id) {
                    localStorage.setItem('therapist_id', loginData.result_info.therapist_id);
                }
                if (loginData.is_first_time_login === 'Y') {
                    navigate('/app/change-password');
                    return;
                } else if (loginData.is_first_time_login === 'N' && (+loginData.role === 2)) {
                    navigate('/app/dashboard');
                    return;
                } else {
                    navigate('/app/dashboard');
                    return;
                }
            } else if (loginData.status === 'E' && loginData.result_code === 401) {
                Swal.fire({
                    text: 'Invalid Credentials',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
                return;
            } else if (loginData.status === 'E' && loginData.result_code === 402) {
                Swal.fire({
                    text: 'Invalid Credentials',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000
                })
                return;
            } else if (loginData.status === 'E' && loginData.result_code === 404) {
                Swal.fire({
                    text: 'Invalid Credentials',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000
                })
                return;
            } else if (loginData.status === 'E' && loginData.result_code === 202) {
                Swal.fire({
                    text: 'Account validity expired!',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000
                })
                return;
            } else {
                Swal.fire({
                    text: JSON.stringify(loginData),
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000
                })
                return;
            }
        }
    }

    return (
        <>
            <div className='loginContainer login-page d-none d-lg-block'>
                <div className="row g-0 h-100 w-100">
                    <div className="col-6 d-none d-lg-block">
                        <div className="loginLeft">
                            <h5 className='headingText'><strong>Welcome to IMPAXIFY!</strong></h5>
                            <img src={require('../../assets/impaxify-child-therapy-center-softwares.jpg')} alt="IMPAXIFY - Software-for-child-development-centers" className='image' />
                            <p>Software for Child Development Center that allows <br className='d-none d-lg-block' /> therapy center owners to streamline and automate their business process</p>
                            <p>Developed by <a href="https://impaxivesolutions.com" className='text-danger text-decoration-none' target='_blank' rel="noreferrer">Impaxive Solutions</a></p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 h-100">
                        <div className="loginRight h-100">
                            <h5 className='loginText text-white'>Login to your Account</h5>
                            <TextField
                                id="outlined-basic"
                                label="Email Address"
                                variant="outlined"
                                className='mb-4 cust-white-input-field'
                                autoComplete='off'
                                onChange={(ev) => {
                                    setUserObj({ ...userObj, email: ev.target.value })
                                }}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                className='mb-4 cust-white-input-field'
                                autoComplete='off'
                                type={showPassword ? 'text' : 'password'}
                                onInput={(ev) => {
                                    setUserObj({ ...userObj, password: ev.target.value })
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton className='text-white'
                                            aria-label="toggle password visibility"
                                            onClick={() => { setShowPassword(!showPassword) }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                onKeyDown={(ev) => {
                                    if (ev.key === "Enter") {
                                        ev.preventDefault();
                                        submit();
                                    }
                                }
                                }
                            />
                            <div className='d-lg-block d-none'>
                                <button className='btn btn-white px-4 rounded-pill text-uppercase' onClick={submit}>Login</button>
                            </div>
                            <div className="text-center mt-lg-3 mb-3 mb-lg-0">
                                <button className='btn btn-light' onClick={() => { navigate('/app/forgot-password') }}>Forgot password?</button>
                            </div>
                            <div className='d-lg-none d-block w-100'>
                                <button className='btn btn-white px-4 rounded-pill text-uppercase ' onClick={submit}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mobileScreen d-lg-none'>
                <div className='text-center wlcm-bgColor'>
                    <h5 className='wlcm-heading'>Welcome Back!</h5>
                    <div className='curvy-div'>
                        <div className='innercurvy-div'>
                        </div>
                    </div>
                </div>
                <div className="loginScreen">
                    <h5 className='loginText text-start mx-4 mb-3'>Login</h5>
                    <div className='px-4'>
                        <TextField
                            id="outlined-basic"
                            label="Email Address"
                            variant="outlined"
                            className='mb-4 cust-white-input-field'
                            autoComplete='off'
                            onChange={(ev) => {
                                setUserObj({ ...userObj, email: ev.target.value })
                            }}
                        />
                    </div>
                    <div className='px-4'>
                        <TextField
                            id="outlined-basic"
                            label="Password"
                            variant="outlined"
                            className='mb-4 cust-white-input-field'
                            autoComplete='off'
                            type={showPassword ? 'text' : 'password'}
                            onInput={(ev) => {
                                setUserObj({ ...userObj, password: ev.target.value })
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton className='text-black'
                                        aria-label="toggle password visibility"
                                        onClick={() => { setShowPassword(!showPassword) }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                            onKeyDown={(ev) => {
                                if (ev.key === "Enter") {
                                    ev.preventDefault();
                                    submit();
                                }
                            }
                            }
                        />
                    </div>

                    <div className="text-center mt-lg-3 mb-3 mb-lg-0">
                        <button className='btn text-black' onClick={() => { navigate('/app/forgot-password') }}>Forgot password?</button>
                    </div>
                    <div className='text-center mx-4'>
                        <button className='btn createButton px-4 rounded-pill text-uppercase w-100' onClick={submit}>Login</button>
                    </div>
                </div>
                <div className='footer-text mx-4 text-center'>
                    <h6>Powered by <span className='mobileThemeColor'> Impaxify </span></h6>
                </div>
            </div>

        </>

    )
}

export default Login
