import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./changepassword.scss";
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2';
import { changePassword } from "../../Api";
import { ArrowBack } from '@mui/icons-material';

function ChangePassword() {
    const navigate = useNavigate()
    const [userObj, setUserObj] = useState({ oldPassword: "", newPassword: "", confirmPassword: "" });
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)

    const submit = async () => {
        let formData = { ...userObj };
        if (!formData.oldPassword) {
            Swal.fire({
                text: 'Old password required!',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
            return;
        } else if (!userObj.newPassword) {
            Swal.fire({
                text: 'New password required!',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
            return;
        } else if (userObj.oldPassword === userObj.newPassword) {
            Swal.fire({
                text: 'New password should not match with old password!',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
            return;
        } else if (!userObj.confirmPassword) {
            Swal.fire({
                text: 'Confirm password required!',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
            return;
        } else if (userObj.confirmPassword !== userObj.newPassword) {
            Swal.fire({
                text: 'New password should match with confirm password!',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
            return;
        } else {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            const correl_id = localStorage.getItem('correl_id');
            let resData = await changePassword({ ...formData, correl_id });
            if (resData.status === 'S') {
                Swal.fire({
                    text: 'Password changed, Redirecting to Login!',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => { navigate('/app/login'); })
            } else if (resData.status === 'E' && resData.result_code === 404) {
                Swal.fire({
                    text: 'User not found',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
                return;
            } else if (resData.status === 'E' && resData.result_code === 401) {
                Swal.fire({
                    text: 'Invalid Old password',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
                return;
            } else if (resData.status === 'E' && resData.result_code === 400) {
                Swal.fire({
                    text: 'New password and confirm password does not match',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000
                })
                return;
            } else if (resData.status === 'F' && resData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else if (resData.status === 'F' && resData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else {
                Swal.fire({
                    text: JSON.stringify(resData),
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000
                })
                return;
            }
        }
    }
    const handleGoBack = () => {
        navigate(-1); // Equivalent to history.goBack()
    };
    return (
        <>
            <div className='loginContainer pwd-screen d-none d-lg-block'>
                <div className="row g-0 h-100 w-100">
                    <div className="col-6 d-none d-lg-block">
                        <div className="loginLeft">
                            <h5 className='headingText text-black'><strong>Welcome to IMPAXIFY!</strong></h5>
                            <img src={require('../../assets/impaxify-child-therapy-center-softwares.jpg')} alt="IMPAXIFY - Software-for-child-development-centers" className='image' />
                            <p>Software for Child Development Center that allows <br className='d-none d-lg-block' /> therapy center owners to streamline and automate their business process</p>
                            <p>Developed by <a href="https://impaxivesolutions.com" className='text-danger text-decoration-none fw-700' target='_blank' rel="noreferrer">Impaxive Solutions</a></p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 h-100">
                        <div className="loginRight h-100">
                            <h5 className='loginText text-white'>Change Password</h5>
                            <TextField
                                id="outlined-basic"
                                label="Old Password"
                                variant="outlined"
                                type={showPassword ? 'text' : 'password'}
                                className='mb-4 cust-white-input-field w-100'
                                autoComplete='off'
                                onChange={(ev) => {
                                    setUserObj({ ...userObj, oldPassword: ev.target.value })
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton className='text-white'
                                            aria-label="toggle password visibility"
                                            onClick={() => { setShowPassword(!showPassword) }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                            <TextField
                                id="outlined-basic"
                                label="New Password"
                                variant="outlined"
                                type={showPassword1 ? 'text' : 'password'}
                                className='mb-4 cust-white-input-field w-100'
                                autoComplete='off'
                                onInput={(ev) => {
                                    setUserObj({ ...userObj, newPassword: ev.target.value })
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton className='text-white'
                                            aria-label="toggle password visibility"
                                            onClick={() => { setShowPassword1(!showPassword1) }}
                                            edge="end"
                                        >
                                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Confirm Password"
                                variant="outlined"
                                type={showPassword2 ? 'text' : 'password'}
                                className='mb-4 cust-white-input-field w-100'
                                autoComplete='off'
                                onInput={(ev) => {
                                    setUserObj({ ...userObj, confirmPassword: ev.target.value })
                                }}
                                onKeyDown={(ev) => {
                                    if (ev.key === "Enter") {
                                        ev.preventDefault();
                                        submit();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton className='text-white'
                                            aria-label="toggle password visibility"
                                            onClick={() => { setShowPassword2(!showPassword2) }}
                                            edge="end"
                                        >
                                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                            <div>
                                <button className='btn btn-white px-4 rounded-pill text-uppercase'
                                    onClick={submit}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-block d-lg-none pwd-screen '>
                <div className="bg-color">
                    <div className='mb-3'>
                        <ArrowBack className='text-white' style={{ cursor: 'pointer' }} onClick={handleGoBack} />
                    </div>
                    <div className='text-center'>
                        <h5 className="page-heading text-white m-0">Change Password</h5>
                    </div>
                </div>
                <div className="row d-block d-lg-none contentContainer">
                    <div className="col-12 p-4">
                        <TextField
                            id="outlined-basic"
                            label="Old Password"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            className='mb-4 w-100 cust-violet-input-field'
                            autoComplete='off'
                            onChange={(ev) => {
                                setUserObj({ ...userObj, oldPassword: ev.target.value })
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton className='text-black'
                                        aria-label="toggle password visibility"
                                        onClick={() => { setShowPassword(!showPassword) }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            label="New Password"
                            variant="outlined"
                            type={showPassword1 ? 'text' : 'password'}
                            className='mb-4 w-100 cust-violet-input-field'
                            autoComplete='off'
                            onInput={(ev) => {
                                setUserObj({ ...userObj, newPassword: ev.target.value })
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton className='text-black'
                                        aria-label="toggle password visibility"
                                        onClick={() => { setShowPassword1(!showPassword1) }}
                                        edge="end"
                                    >
                                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Confirm Password"
                            variant="outlined"
                            type={showPassword2 ? 'text' : 'password'}
                            className='mb-4 w-100 cust-violet-input-field'
                            autoComplete='off'
                            onInput={(ev) => {
                                setUserObj({ ...userObj, confirmPassword: ev.target.value })
                            }}
                            onKeyDown={(ev) => {
                                if (ev.key === "Enter") {
                                    ev.preventDefault();
                                    submit();
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton className='text-black'
                                        aria-label="toggle password visibility"
                                        onClick={() => { setShowPassword2(!showPassword2) }}
                                        edge="end"
                                    >
                                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                        <div className='text-center w-100'>
                            <button className='btn createButton px-4 rounded-pill text-uppercase w-100'
                                onClick={submit}
                            >Submit</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ChangePassword
