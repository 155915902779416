import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./managepayments.scss"
import { TextField } from '@mui/material'
import { ArrowBack, ChevronRight, ControlPoint, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { deleteFeePayment, getMonthlyPayments, getPaymentDues, getPayments, getPendingPayments } from '../../../Api';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function ManagePayments() {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [totalPages, setTotalPages] = useState(null);
    const [activeTab, setActiveTab] = useState('paid');
    const [content, setContent] = React.useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const monthName = (num) => {
        return months[num];
    }

    const filteredData = [...content].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const edit = (payment_id) => {
        navigate('/app/edit-payments/' + payment_id);
    }
    const delPayment = (payment_id) => {
        Swal.fire({
            text: `Are you sure you want to delete the payment?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let apiRes = await deleteFeePayment(payment_id);
                console.log(apiRes);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: `Payment Deleted`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        fetchData();
                    });
                }
            }
        })
    }

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let acc_code = userData.account_code;
        let apiData;
        // if (activeTab === "paid") {
        apiData = await getMonthlyPayments(acc_code, selectedDate.getMonth(), selectedDate.getFullYear());
        // }
        if (apiData && apiData.status === "S") {
            setContent(apiData.result_info);
            setTotalPages(Math.ceil([...apiData.result_info].length / pageSize));
        } else {
            setContent([]);
            setTotalPages(0);
        }

        // apiData.status === "S" ?  setContent(apiData.result_info) : setContent([]);
        // apiData.status === "S" ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)): setTotalPages(0);
        if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
        if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
    }
    useEffect(() => {
        const fetchPayments = async () => {
            if (selectedDate) {
                let role = 2;
                const userData = JSON.parse(localStorage.getItem('userData') || '{}');
                const acc_code = userData.account_code;
                let apiData;
                if (role === 2) {
                    if (activeTab === 'paid') {
                        apiData = await getMonthlyPayments(acc_code, selectedDate.getMonth(), selectedDate.getFullYear());
                    } else if (activeTab === 'dues') {
                        apiData = await getPaymentDues(acc_code, selectedDate.getMonth(), selectedDate.getFullYear());
                    }
                }
                if (apiData.status === "S") {
                    setContent(apiData.result_info);
                    const totalPagesCount = Math.ceil(apiData.result_info.length / pageSize);
                    setTotalPages(totalPagesCount > 0 ? totalPagesCount : 1);
                } else {
                    setContent([]);
                    setTotalPages(0);
                }
            }
        };

        fetchPayments();
    }, [selectedDate, activeTab, navigate]);

    // useEffect(() => {
    //    fetchData()
    // }, [navigate])

    return (
        <div className='manageContainer'>
            <h5 className='page-heading'>Manage Payments</h5>
            <div className='searchbar-div mb-4'>
                <div className='searchbar'>
                    <TextField id="standard-basic" label="Search" variant="standard" value={searchQuery} onChange={handleSearchChange} />
                </div>
                <div className='d-flex align-items-center'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label={'Month and Year'} views={['month', 'year']} className='me-3 cust-violet-input-field'
                            value={dayjs(selectedDate)}
                            onChange={(ev) => {
                                setSelectedDate(ev["$d"])
                            }} />
                    </LocalizationProvider>
                    <Link to="/app/view-record-fee-payment">
                        <div className="buttonCreate">
                            <button className='create'>+ Create</button>
                        </div>
                    </Link>
                </div>

            </div>
            <div className="card manage-card">
                <div className="tableContainer common-table">
                    <div>
                        <div className='text-center justify-content-center mb-3'>
                            <button
                                className={`tab-button ${activeTab === 'paid' ? 'active' : ''}`}
                                onClick={() => {
                                    setActiveTab('paid');
                                }}
                            >
                                Paid
                            </button>
                            <button
                                className={`tab-button ${activeTab === 'dues' ? 'active' : ''}`}
                                onClick={() => {
                                    setActiveTab('dues');
                                }}
                            >
                                Due
                            </button>

                        </div>
                        {activeTab === 'dues' && (
                            <div className='justify-content-end text-end mb-3'>
                                <button className="btn createButton"
                                >Send Reminder</button>
                            </div>
                        )}
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Student Name</th>
                                <th>Month</th>
                                <th>Year</th>
                                {activeTab === 'paid' && (<>
                                    <th className='col-width'>Actions</th>
                                </>)}
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.student_name}</td>
                                    <td>{monthName(activeTab === 'paid' ? row.month : row.fee_month)}</td>
                                    <td>{(activeTab === 'paid' ? row.year : row.fee_year)}</td>
                                    {activeTab === 'paid' && (<>
                                        <td className='col-width'>
                                            <button className="btn cancelButton px-3" onClick={() => { delPayment(row.payment_id) }}>Delete</button>
                                        </td>
                                    </>)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                            <KeyboardArrowLeft />
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                            <KeyboardArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ManagePayments
