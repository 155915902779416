import React, { useEffect, useState } from 'react'
import "./createstudent.scss"
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField, styled } from '@mui/material';
import Swal from 'sweetalert2';
import { createStudent, getTherapies } from '../../../Api';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    width: '100%',
    color: '#000',
    '&.Mui-focused': {
        color: '#000',
    },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8267ed',
    },
}));
const ColoredCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.main,
    '&.Mui-checked': {
        color: '#8267ed',
        borderColor: '#8267ed',
    },
}));
function MobileCreateStudent() {
    const navigate = useNavigate();
    const [selectedTherapies, setSelectedTherapies] = useState([])
    const [therapies, setTherapies] = useState([]);

    const [content, setContent] = React.useState({
        student_name: "",
        student_dob: null,
        parent_name: "",
        parent_mobile: "",
        parent_email: "",
        specialization: [],
        fees: '',
        notes: "",
    })

    const handleTherapyChange = (event) => {
        const { value } = event.target;
        setSelectedTherapies(value);
        console.log(value);
        const selectedTherapy = value.map(therapy => therapy.therapy_id);
        setContent({ ...content, specialization: selectedTherapy });
    };
    const submit = async () => {
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: { popup: 'loader-swal' }
        });
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if (!content.student_name) {
            Swal.fire({
                text: 'Please provide Student name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.student_name.length > 255) {
            Swal.fire({
                text: 'Student name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.parent_email && !emalval.test(content.parent_email)) {
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.parent_name) {
            Swal.fire({
                text: 'Please provide Parent name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.parent_name.length > 255) {
            Swal.fire({
                text: 'Parent name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.parent_email.length > 255) {
            Swal.fire({
                text: 'Parent email should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.student_dob) {
            Swal.fire({
                text: 'Please select Date of Birth',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.parent_mobile) {
            Swal.fire({
                text: 'Please provide Mobile number',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.parent_mobile.length > 255) {
            Swal.fire({
                text: 'Mobile Number should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (selectedTherapies.length === 0) {
            Swal.fire({
                text: 'Please select therapies',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.fees) {
            Swal.fire({
                text: 'Please enter fees',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let data = { ...content };
            if (data.parent_email) {
                data.parent_email = data.parent_email.toLowerCase();
            }
            data.enrolled_therapies = data.specialization.join(",");
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            data.account_code = userData.account_code;
            console.log(data);
            let apiData = await createStudent({ ...data });
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Student created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-students')
                })
            }
            if (apiData.status === "E") {
                Swal.fire({
                    text: apiData.message,
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let acc_code = userData.account_code;
            let apiData = await getTherapies(acc_code);
            apiData.status === "S" ? setTherapies(apiData.result_info) : setTherapies([]);
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        })();
    }, [navigate])
    const handleGoBack = () => {
        navigate(-1); // Equivalent to history.goBack()
    };
    return (
        <div className='mobile-createStudent'>
            <div className="bg-color">
                <div className='mb-3'>
                    <ArrowBack className='text-white' style={{ cursor: 'pointer' }} onClick={handleGoBack} />
                </div>
                <div className='text-center'>
                    <h5 className="page-heading text-white mb-2">Create Student</h5>
                </div>
            </div>
            <div className='contentContainer px-4'>
                <div className="row mb-lg-3 mt-3">
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Student Name" variant="outlined" autoComplete="off" required
                            value={content.student_name} onChange={(ev) => {
                                setContent({ ...content, student_name: ev.target.value })
                            }} />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Date of Birth *" className='w-100 cust-violet-input-field'
                                defaultValue={dayjs(content.student_dob)}
                                value={dayjs(content.student_dob)}
                                onChange={(ev) => {
                                    let date = ev?.$d;
                                    setContent({ ...content, student_dob: dayjs(date).format('YYYY-MM-DD') })
                                }} />

                        </LocalizationProvider>
                    </div>
                </div>
                <div className="row mb-lg-3">
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Parent Name" variant="outlined" autoComplete="off" required
                            value={content.parent_name} onChange={(ev) => {
                                setContent({ ...content, parent_name: ev.target.value })
                            }} />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Phone" variant="outlined" autoComplete="off" required
                            value={content.parent_mobile} onChange={(ev) => {
                                setContent({ ...content, parent_mobile: ev.target.value })
                            }} />
                    </div>
                </div>
                <div className="row mb-lg-3">
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Email" variant="outlined" autoComplete="off" required
                            value={content.parent_email} onChange={(ev) => {
                                setContent({ ...content, parent_email: ev.target.value })
                            }} />
                    </div>

                </div>
                <div className="row mb-lg-3">
                    <div className="col-12 mb-3">
                        <FormControl fullWidth required>
                            <CustomInputLabel id="demo-multiselect-label">Enrolled Therapies</CustomInputLabel>
                            <CustomSelect
                                labelId="demo-multiselect-label"
                                id="demo-multiselect"
                                required
                                multiple
                                value={selectedTherapies}
                                onChange={handleTherapyChange}
                                label="Enrolled Therapies"
                                renderValue={(selected) => {
                                    let name = ''
                                    selected.forEach((val) => {
                                        name = name ? name + ', ' + val.therapy_name : name + val.therapy_name
                                    })
                                    return name;
                                }}
                            >
                                {therapies.map((therapy) => (
                                    <MenuItem key={therapy.therapy_id} value={therapy}>
                                        <ColoredCheckbox checked={selectedTherapies.some(selected => selected.therapy_id === therapy.therapy_id)} />
                                        <ListItemText primary={therapy.therapy_name} />
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Fees" variant="outlined" autoComplete="off" required
                            value={content.fees} onChange={(ev) => {
                                setContent({ ...content, fees: ev.target.value })
                            }} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 mb-5">
                        <TextField
                            className="w-100 cust-violet-input-field"
                            id="outlined-basic"
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={3}
                            autoComplete="off"
                            value={content.notes} onChange={(ev) => {
                                setContent({ ...content, notes: ev.target.value })
                            }}
                        />
                    </div>
                </div>
                <div className="row buttonsRow d-flex px-4 fixed-bottom bg-white py-3">
                    <button className='cancelButton me-2 flex-1' onClick={() => { navigate('/app/manage-students') }}>Cancel</button>
                    <button className='createButton flex-1' onClick={() => { submit() }}>Create</button>
                </div>

            </div>
        </div>
    )
}

export default MobileCreateStudent
