import React, { useEffect, useState } from 'react'
import "./createtherapy.scss"
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createTherapy } from '../../../Api';
import { ArrowBack } from '@mui/icons-material';
import { TextField } from '@mui/material';

function MobileCreateTherapy() {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null)
    const [content, setContent] = React.useState({
        therapy_name: "",
    })
    const submit = async () => {
        if (!content.therapy_name) {
            Swal.fire({
                text: 'Please provide Therapy name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.therapy_name.length > 255) {
            Swal.fire({
                text: 'Therapy name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let data = { ...content };
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            data.account_code = userData.account_code;
            let apiData = await createTherapy({ ...data });
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Therapy created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-therapies')
                })
            }
            if (apiData.status === "E") {
                Swal.fire({
                    text: apiData.message,
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            setUserInfo(userData);
        })()
    }, [])
    const handleGoBack = () => {
        navigate(-1); // Equivalent to history.goBack()
    };
    return (
        <div className='mobile-createtherapy'>
            <div className="bg-color">
                <div className='mb-3'>
                    <ArrowBack className='text-white' style={{ cursor: 'pointer' }} onClick={handleGoBack} />
                </div>
                <div className='text-center'>
                    <h5 className="page-heading text-white mb-2">Create Therapy</h5>
                </div>
            </div>
            <div className='contentContainer mt-3'>
                <div className="row mb-3">
                    <div className="col-12 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Therapy Name" variant="outlined" autoComplete="off" required onChange={(ev) => {
                            setContent({ ...content, therapy_name: ev.target.value })
                        }} />
                    </div>
                </div>
                <div className="row buttonsRow d-flex px-3 mt-2">
                    <button className='cancelButton  me-2 flex-1' onClick={() => { navigate('/app/manage-therapies') }}>Cancel</button>
                    <button className='createButton flex-1' onClick={() => { submit() }}>Create</button>
                </div>
            </div>

        </div>
    )
}

export default MobileCreateTherapy
