import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox, ListItemText, TextField } from '@mui/material';
import { getTherapyLogInfo } from '../../../Api';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
        '-webkit-text-fill-color': 'black', /* Set the text color to black for disabled inputs */
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
            borderColor: 'black', // Optional: change border color to indicate disabled state
        },
    },
}));

function SessionFeedback() {
    const navigate = useNavigate();
    const { therapy_log_id } = useParams();
    const [content, setContent] = useState(null);

    const [userInfo, setUserInfo] = useState(null);


    const fetchTherapylogs = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserInfo(userData);
        let apiData = await getTherapyLogInfo(therapy_log_id);
        if (apiData.status === "S") {
            setContent(apiData.result_info);
        }
    }

    useEffect(() => {
        fetchTherapylogs();
    }, []);


    return (

        <div className='editContainer'>
            <h5 className='page-heading'>Session Feedback</h5>
            {content &&
                <>
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                            <CustomTextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Date" variant="outlined" autoComplete="off" required
                                value={content.date} disabled />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <CustomTextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Student name" variant="outlined" autoComplete="off" required
                                value={content.student_name} disabled />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <CustomTextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Therapy name" variant="outlined" autoComplete="off" required
                                value={content.therapy_name} disabled />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <CustomTextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Goal name" variant="outlined" autoComplete="off" required
                                value={content.goal_name} disabled />
                        </div>
                        <div className="col-12 mb-3">
                            {content.activity_names && content.activity_names.length > 0 && content.activity_names.map((activity) =>
                                <div key={activity.activity_id} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox disabled
                                        checked={true}
                                    />
                                    <ListItemText primary={activity} />
                                </div>)}
                        </div>
                        <div className="col-12 mb-3">
                            <CustomTextField className='w-100 cust-violet-input-field' multiline rows={4} id="outlined-basic" label="Comments" variant="outlined" autoComplete="off"
                                value={content.comments} disabled />
                        </div>
                    </div>
                    <div className="row buttonsRow d-flex px-3">
                        <button className='cancelButton flex-1' onClick={() => { navigate('/app/sessions-feedback') }}>Cancel</button>
                    </div>
                </>}
        </div>
    )
}

export default SessionFeedback
